body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  background-color: #F9F9F9;
}

.bg-white {
  background-color: #fff;
  min-height: 100vh;
}

.carousel-indicators li {
  background-color: black;
  opacity: 1;
}
.carousel-indicators .active {
  background-color: green;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
 }

.floating-shop {
  display: none;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .floating-shop {
    display: -ms-grid;
    display: grid;
    position: fixed;
    bottom: 70px;
    right: 10px;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .floating-shop a {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50px;
    height: 50px;
  }

  .floating-shop a .fas {
    font-size: 1.5em;
  }

  .floating-shop .notif {
    position: absolute;
    top: -5px;
    width: 24px;
    height: 24px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 500;
    right: -5px;
  }
}

.hubungikami {
  z-index: 99;
  background-color: transparent;
  width: 200px;
  position: fixed;
  right: -150px;
  bottom: 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: .5fr 1fr .5fr;
      grid-template-rows: .5fr 1fr .5fr;
  row-gap: 10px;
  overflow: hidden;
  -webkit-transition: .5s;
  transition: .5s;
}

.hubungikami .footer {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .hubungikami {
    right: -200px;
    bottom: 125px;
  }
}

.hubungikami .judul {
  cursor: pointer;
  opacity: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  text-decoration: none;
  padding: 10px 14px;
  background-color: #25D366;
}

.hubungikami .judul img {
  width: 25px;
}

.hubungikami .judul p {
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0;
}

.hubungikami .konten-wa {
  opacity: 0;
  background-color: #fff;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  row-gap: 0;
}

.hubungikami .konten-wa a {
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  background-color: #fff;
  margin: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: .3s;
  transition: .3s;
}

.hubungikami .konten-wa a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.hubungikami .konten-wa a p {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 1em;
}

.hubungikami a {
  opacity: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  text-decoration: none;
  padding: 10px 14px;
  background-color: #25D366;
}

.hubungikami a img {
  width: 25px;
}

.hubungikami a p {
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0;
}

.hubungikami.active {
  background-color: #fff;
  right: 0px;
  border-radius: 10px 0 0 10px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: .5s;
  transition: .5s;
}

.hubungikami.active .judul {
  opacity: 1;
}

.hubungikami.active .konten-wa {
  opacity: 1;
}

.btn-daftaragen {
  cursor: pointer;
}

.cover-keranjang-all .cover-card-produk .btn-bayar {
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.cover-keranjang-all .cover-card-produk .btn-bayar a {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  margin: auto;
}

.cover-keranjang-all .cover-card-produk .btn-bayar a.btn-bayar {
  background-color: #ee4d2d;
  width: 50%;
  color: #fff;
}

.pointer {
  cursor: pointer;
}

.nice-number {
  display: inline-flex;
  justify-content: stretch;
}

.nice-number input {
  vertical-align: middle;
  -moz-appearance: textfield;
  box-sizing: content-box;
  margin: 0;
  text-align: center;
}

.cover-katalogheader {
  background-color: #fff;
  margin: -20px 0 60px;
  padding: 40px 20px 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr auto auto;
      grid-template-columns: auto 1fr auto auto;
  border-radius: 8px;
      grid-template-areas: "foto judul filter cari";
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader {
    -ms-grid-rows: 1fr auto;
        grid-template-rows: 1fr auto;
        grid-template-areas: "judul judul judul filter"
 "cari cari cari cari";
    margin-bottom: 40px;
  }
}

.cover-katalogheader .gambar {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: foto;
}

.cover-katalogheader .gambar img {
  width: 150px;
  max-height: 150px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .gambar img {
    display: none;
  }
}

.cover-katalogheader .judul-cat {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: judul;
  margin-left: 30px;
}

.cover-katalogheader .judul-cat h3 {
  font-size: 1.8em;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .judul-cat {
    margin: 0;
  }
  .cover-katalogheader .judul-cat h3 {
    font-size: 1.3em;
  }
}

.cover-katalogheader .cover-filter {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  justify-self: flex-end;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: filter;
}

.cover-katalogheader .cover-filter .dropdowncat {
  position: relative;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  margin-right: 20px;
}

.cover-katalogheader .cover-filter .dropdowncat .dropdown-menu {
  max-height: 30vw;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .cover-filter .dropdowncat .dropdown-menu {
    max-height: 100vw;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .cover-filter .dropdowncat {
    margin: 0;
  }

  .cover-katalogheader .cover-filter .dropdowncat .btn-cat .name {
    font-size: 0.8rem;
    width: 80px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }
}

.cover-katalogheader .cover-filter .dropdowncat .btn-cat {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border: 1px solid transparent !important;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.cover-katalogheader .cover-filter .dropdowncat .btn-cat:active {
  border: 1px solid transparent !important;
}

.cover-katalogheader .cover-cari {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
  grid-area: cari;
  justify-self: flex-end;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .cover-cari {
    width: 100%;
    margin-top: 24px;
  }
}

.cover-katalogheader .cover-cari input {
  height: 33px;
  outline: none;
  width: 200px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .cover-cari input {
    width: 100%;
  }
}

.cover-katalogheader .cover-cari button {
  height: 34px;
  border: .5px solid transparent;
  width: 80px;
  border-radius: 4px;
  margin-left: 10px;
  background-color: #1ED07A;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .cover-katalogheader .cover-cari button {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
}

.cover-judulkatalog .dropdowncat {
  position: relative;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-right: 20px;
}

.cover-judulkatalog .dropdowncat .dropdown-menu {
  max-height: 30vw;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .cover-judulkatalog .dropdowncat .dropdown-menu {
    max-height: 100vw;
    overflow-y: scroll;
  }
}

.cover-judulkatalog .dropdowncat .btn-cat {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border: 1px solid transparent !important;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.cover-judulkatalog .dropdowncat .btn-cat:active {
  border: 1px solid transparent !important;
}

#snackbar {
  cursor: pointer;
  min-width: 250px; 
  text-align: center;
  color: #fff; 
  position: fixed; 
  z-index: 999999; 
  left: 0;
  right: 0;
  bottom: 30px; 
}

#snackbar .content {
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  padding: 16px; 
}

@media only screen and (max-width: 600px) {
  #snackbar {
    padding: 12px; 
  }
}

#snackbar .success {
  background-color: #54B149; 
}

#snackbar .error {
  background-color: #fa591d; 
}

#snackbar.show {
  visibility: visible; 
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

.splash {
  min-height: 100%;  
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparan {
  background-color: #000;
  opacity: .8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: none;
}

.container-besar {
  height: 80px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: #fff;
  z-index: 99;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .container-besar {
    background-color: green;
  }
}

.container-besar nav {
  width: 90%;
  height: 80px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: .8fr 2fr .5fr 1fr;
      grid-template-columns: .8fr 2fr .5fr 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  margin: auto;
}

.container-besar nav img.logo {
  cursor: pointer;
  width: 150px;
}

.container-besar nav .cover-cari {
  position: relative;
}

.container-besar nav .cover-cari input {
  width: 100%;
  height: 35px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}

.container-besar nav .cover-cari #cari {
  position: absolute;
  outline: none;
  padding: 0;
  top: 50%;
  right: 3px;
  height: 30px;
  width: 80px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  border-radius: 8px;
  border: none;
  -webkit-transition: .5s;
  transition: .5s;
}

.container-besar nav .cover-cari #cari:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.container-besar nav .cover-keranjang {
  justify-self: flex-end;
  position: relative;
  cursor: pointer;
}

.container-besar nav .cover-keranjang span.badges-keranjang {
  position: absolute;
  top: 6px;
  right: 1px;
  display: inline-block;
  background-color: red;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 600;
  width: 22px;
  height: 22px;
  padding: 2px;
  text-align: center;
  line-height: 14px;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.container-besar nav .button-action {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
  grid-gap: 20px;
}

.container-besar nav .button-action .btn-masuk {
  cursor: pointer;
  justify-self: flex-end;
  display: inline-block;
  border: 1px solid green;
  color: green;
  height: 35px;
  line-height: 34px;
  border-radius: 8px;
  width: 120px;
  text-align: center;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.container-besar nav .button-action .btn-daftar {
  cursor: pointer;
  justify-self: flex-end;
  display: inline-block;
  background-color: #54B149;
  color: #fff;
  height: 35px;
  line-height: 35px;
  border-radius: 8px;
  width: 120px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .container-besar nav {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    background-color: green;
  }
  .container-besar nav img.logo {
    display: none;
  }
  .container-besar nav .button-action {
    display: none;
  }
  .container-besar nav .cover-keranjang {
    display: none;
  }
}

.covernav-mobile {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: none;
}

.covernav-mobile .nav-mobile {
  height: 60px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.covernav-mobile .nav-mobile a {
  position: relative;
  display: -ms-grid;
  display: grid;
  text-align: center;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  row-gap: 6px;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

.covernav-mobile .nav-mobile a .notif {
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  right: 5px;
}

.covernav-mobile .nav-mobile a .fas {
  font-size: 1.2em !important;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 100%;
}

.covernav-mobile .nav-mobile a p {
  font-size: .7em;
  color: rgba(0, 0, 0, 0.6);
}

.covernav-mobile .nav-mobile a.active {
  color: green;
}

.covernav-mobile .nav-mobile a.active p {
  font-size: .7em;
  color: green;
}

@media only screen and (max-width: 600px) {
  .covernav-mobile {
    display: block;
  }
}

.iklan-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .iklan-mobile {
    display: block;
  }
  .iklan-mobile #carouselExampleIndicators {
    margin-top: 80px;
  }
  .iklan-mobile #carouselExampleIndicators img.d-block {
    height: 140px;
  }
}

.container #carouselExampleIndicators {
  margin-top: 140px;
}

.container #carouselExampleIndicators img.d-block {
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .container #carouselExampleIndicators {
    display: none;
  }
}

.cover-welcome {
  margin: 70px 0;
  text-align: center;
  font-size: 1.5em;
}

.cover-welcome .name {
  color: green;
}

@media only screen and (max-width: 600px) {
  .cover-welcome {
    margin: 20px 0;
    font-size: 1.2em;
  }
}

.cover-kategori, .cover-kategori-all {
  margin: 70px 0;
  border-radius: 4px;
  background-color: #fff;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr;
      grid-template-rows: auto 1fr;
}

@media only screen and (max-width: 600px) {
  .cover-kategori, .cover-kategori-all {
    margin: 20px 0 0;
  }
}

.cover-kategori .judul, .cover-kategori-all .judul {
  position: relative;
  padding: 20px 20px 40px;
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}


.cover-kategori .judul .btn-viewall {
  cursor: pointer;
  font-size: 16px;
}

.cover-kategori .judul img, .cover-kategori-all .judul img {
  position: absolute;
  top: 20px;
  left: -9px;
}

@media only screen and (max-width: 600px) {
  .cover-kategori .judul img {
    width: 160px;
  }
}

.cover-kategori .judul a, .cover-kategori-all .judul a {
  position: relative;
  justify-self: flex-end;
  text-decoration: none;
  font-size: .9em;
  margin-right: 10px;
  color: #fa591d;
  font-weight: 500;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .cover-kategori .judul, .cover-kategori-all .judul {
    padding: 20px 20px 50px;
  }
  .cover-kategori .judul a, .cover-kategori-all .judul a {
    margin-top: 20px;
  }
}

.cover-kategori .content-kategori, .cover-kategori .content-kategori-all, .cover-kategori-all .content-kategori, .cover-kategori-all .content-kategori-all {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(150px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .cover-kategori .content-kategori, .cover-kategori .content-kategori-all, .cover-kategori-all .content-kategori, .cover-kategori-all .content-kategori-all {
    -ms-grid-columns: (minmax(120px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .cover-kategori .content-kategori .card-kategori, .cover-kategori .content-kategori-all .card-kategori, .cover-kategori-all .content-kategori .card-kategori, .cover-kategori-all .content-kategori-all .card-kategori {
    padding: 15px 15px 15px !important;
  }
}

.cover-kategori .content-kategori .card-kategori, .cover-kategori .content-kategori-all .card-kategori, .cover-kategori-all .content-kategori .card-kategori, .cover-kategori-all .content-kategori-all .card-kategori {
  /* display: -ms-grid;
  display: grid; */
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
  row-gap: 20px;
  border-right: 0.2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
  padding: 20px 30px 10px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: .3s;
  transition: .3s;
}

.cover-kategori .content-kategori .card-kategori img, .cover-kategori .content-kategori-all .card-kategori img, .cover-kategori-all .content-kategori .card-kategori img, .cover-kategori-all .content-kategori-all .card-kategori img {
  width: 100%;
  border-radius: 6px;
}

.cover-kategori .content-kategori .card-kategori p.judul-kategori, .cover-kategori .content-kategori-all .card-kategori p.judul-kategori, .cover-kategori-all .content-kategori .card-kategori p.judul-kategori, .cover-kategori-all .content-kategori-all .card-kategori p.judul-kategori {
  text-align: center;
  margin-top: 1rem;
}

.cover-kategori .content-kategori .card-kategori.card-kategori:nth-child(8), .cover-kategori .content-kategori-all .card-kategori.card-kategori:nth-child(8), .cover-kategori-all .content-kategori .card-kategori.card-kategori:nth-child(8), .cover-kategori-all .content-kategori-all .card-kategori.card-kategori:nth-child(8) {
  border-right: none;
}

.cover-kategori .content-kategori .card-kategori.card-kategori:last-child, .cover-kategori .content-kategori-all .card-kategori.card-kategori:last-child, .cover-kategori-all .content-kategori .card-kategori.card-kategori:last-child, .cover-kategori-all .content-kategori-all .card-kategori.card-kategori:last-child {
  border-right: none;
}

.cover-kategori .content-kategori .card-kategori:hover, .cover-kategori .content-kategori-all .card-kategori:hover, .cover-kategori-all .content-kategori .card-kategori:hover, .cover-kategori-all .content-kategori-all .card-kategori:hover {
  -webkit-box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.2);
}

.cover-kategori-all {
  -ms-grid-rows: auto 1fr auto;
      grid-template-rows: auto 1fr auto;
  z-index: 9999;
  max-height: 600px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 10px;
  width: 80%;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .cover-kategori-all {
    width: 100%;
    top: -20px;
    bottom: 0;
    border-radius: 0;
    max-height: 100%;
  }
}

.cover-kategori-all .judul {
  padding: 20px;
}

.cover-kategori-all .judul h2 {
  font-size: 1em;
}

.cover-kategori-all .btn-kategori-modal {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 20px 0;
  width: 100%;
  cursor: pointer;
}

.cover-kategori-all .btn-kategori-modal a {
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 40px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  justify-items: flex-end;
  margin-right: 20px;
}

.cover-judulkatalog {
  margin: 20px 0;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 95px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

.cover-more {
  margin: 0;
  width: 100%;
  height: 125px;
  text-align: center;
}

.cover-more .btn-more {
  cursor: pointer;
  justify-self: flex-end;
  display: inline-block;
  border: 1px solid green;
  color: green;
  line-height: 34px;
  border-radius: 8px;
  padding: 0 28px;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cover-more .text-more {
  justify-self: flex-end;
  display: inline-block;
  color: green;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cover-more .btn-more:hover {
  color: green;
}

.cover-judulkatalog .judul {
  position: relative;
  display: -ms-grid;
  display: grid;
}

.cover-judulkatalog .judul img {
  position: absolute;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  left: -9px;
}

@media only screen and (max-width: 600px) {
  .cover-judulkatalog .judul img {
    width: 200px;
  }

  .cover-judulkatalog .dropdowncat .btn-cat .name {
    font-size: 0.8rem;
    width: 80px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }
}

.cover-judulkatalog .judul h1 {
  position: absolute;
  font-size: 1.3em;
  color: #fff;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.cover-katalog {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(160px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 20px;
  margin-bottom: 100px;
}

@media screen and (max-width: 600px) {
  .cover-katalog {
    -ms-grid-columns: (minmax(150px, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    margin-bottom: 20px;
  }
}

.cover-katalog .card-catalog {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
}

.cover-katalog .card-catalog:hover {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.cover-katalog .card-catalog img {
  width: 100%;
  height: 170px;
}

.cover-katalog .card-catalog .judul-produk {
  font-size: .9em;
  font-weight: 500;
  margin: 15px 10px 0;
  max-width: 100%;
  height: auto;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cover-katalog .card-catalog .produsen-produk {
  font-size: .8em;
  font-weight: 400;
  margin: 5px 10px 0;
  color: rgba(0, 0, 0, 0.6);
}

.cover-katalog .card-catalog .diskon {
  font-size: .7em;
  font-weight: 600;
  margin: 12px 5px 0 10px;
  color: rgba(0, 0, 0, 0.4);
  color: #FF2D5F;
  background-color: #ffeaef;
  padding: 4px 8px;
  border-radius: 6px;
  display: inline-block;
}

.cover-katalog .card-catalog .harga-diskon {
  font-size: .8em;
  font-weight: 400;
  margin: 3px 10px 0 0;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
}

.cover-katalog .card-catalog .harga-produk {
  font-size: 1.1em;
  font-weight: 600;
  margin: 3px 10px 0;
  color: #fa591d;
}

.cover-headerdaftaragen {
  margin: 60px 0 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr .2fr;
      grid-template-columns: 1fr .2fr;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
  row-gap: 20px;
}

.cover-headerdaftaragen .judul {
  margin: 0;
}

.cover-headerdaftaragen .judul p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em;
  letter-spacing: .09em;
}

.cover-headerdaftaragen .step {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.cover-headerdaftaragen .step p {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1em;
  background-color: #FFCC47;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  font-weight: 500;
  padding: 5px;
  line-height: 20px;
}

.cover-daftaragen {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  margin: 70px 0;
}

@media only screen and (max-width: 600px) {
  .cover-daftaragen {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    margin: 20px 0;
  }
}

.cover-daftaragen .card-daftaragen {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-right: 20px;
  background-color: #fff;
  padding-bottom: 10px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
}

.cover-daftaragen .card-daftaragen:hover {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.cover-daftaragen .card-daftaragen:nth-child(3) {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.cover-daftaragen .card-daftaragen:nth-child(3) .btn-pilih {
  background-color: #1ED07A;
  color: #fff;
  border: none;
}

.cover-daftaragen .card-daftaragen:nth-child(3) .btn-pilih:hover {
  background-color: #098549;
}

@media only screen and (max-width: 600px) {
  .cover-daftaragen .card-daftaragen:nth-child(3) {
    -webkit-transform: none;
            transform: none;
  }
}

.cover-daftaragen .card-daftaragen .judul-agen {
  font-size: 1.3em;
  font-weight: 500;
  margin: 15px 10px 20px;
  max-width: 100%;
  height: auto;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.cover-daftaragen .card-daftaragen .diskon {
  font-size: 1.1em;
  font-weight: 600;
  margin: 12px 5px 10px 10px;
  color: rgba(0, 0, 0, 0.4);
  color: #FF2D5F;
  background-color: #ffeaef;
  padding: 4px 8px;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
}

.cover-daftaragen .card-daftaragen .produsen-produk {
  font-size: .8em;
  font-weight: 400;
  margin: 5px 10px 0;
  color: rgba(0, 0, 0, 0.6);
}

.cover-daftaragen .card-daftaragen .minimal, .cover-daftaragen .card-daftaragen .keterangan {
  font-size: .8em;
  font-weight: 400;
  margin: 5px 10px 0;
  color: rgba(0, 0, 0, 0.6);
}

.cover-daftaragen .card-daftaragen .harga {
  font-size: 1.1em;
  font-weight: 600;
  margin: 3px 10px 10px;
  color: #fa591d;
}

.cover-daftaragen .card-daftaragen .btn-pilih {
  outline: none;
  border: none;
  height: 40px;
  width: 90%;
  border-radius: 6px;
  background-color: #fff;
  color: #35D689;
  border: 1px solid #35D689;
  font-weight: 500;
  -webkit-transition: .5s;
  transition: .5s;
  margin: 25px auto 10px;
}

.cover-daftaragen .card-daftaragen .btn-pilih:hover {
  background-color: #35D689;
  color: #fff;
  border: none;
}

@media only screen and (max-width: 600px) {
  .cover-daftaragen .card-daftaragen {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .cover-daftaragen .card-daftaragen .btn-pilih {
    width: 90%;
    margin: 20px auto 0;
  }
}

/* .cover-pagedaftarform {
  display: none;
} */

.cover-pagedaftarform .formdaftar {
  margin: 40px auto;
}

@media only screen and (max-width: 600px) {
  .cover-pagedaftarform .formdaftar {
    width: 100%;
    margin: 20px auto;
  }
}

.cover-pagedaftarform .formdaftar .card-daftar {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr auto auto auto auto;
      grid-template-rows: auto 1fr auto auto auto auto;
  row-gap: 20px;
}

@media only screen and (max-width: 600px) {
  .cover-pagedaftarform .formdaftar .card-daftar {
    width: 100%;
  }
}

.cover-pagedaftarform .formdaftar .card-daftar label {
  display: block;
  margin-bottom: 8px;
  font-size: .9em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.cover-pagedaftarform .formdaftar .card-daftar input {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  font-size: .9em;
}

.cover-pagedaftarform .formdaftar .card-daftar textarea {
  width: 100%;
  border-radius: 6px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  font-size: .9em;
}

.cover-pagedaftarform .formdaftar .card-daftar .cover-btndaftar {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 16px;
          column-gap: 16px;
}

.cover-pagedaftarform .formdaftar .card-daftar .cover-btndaftar .btn-sebelumnya {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  text-align: center;
  line-height: 40px;
  font-weight: 500;
  font-size: 1em;
  height: 40px;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin: 20px 0;
}

.cover-pagedaftarform .formdaftar .card-daftar .cover-btndaftar .btn-daftar {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  background-color: #1ED07A;
  text-align: center;
  line-height: 40px;
  font-weight: 500;
  font-size: 1em;
  height: 40px;
  color: #fff;
  border-radius: 6px;
  margin: 20px 0;
}

.clickable {
  cursor: pointer;
  color: #1ED07A !important;
}

.breadcrumb-halaman {
  margin-top: 80px;
  position: relative;
}

.cover-detail {
  margin-top: 60px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

@media only screen and (max-width: 600px) {
  .cover-detail {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    row-gap: 40px;
    margin-top: 20px;
  }
}

.cover-detail img {
  width: 100%;
  border-radius: 8px;
  height: 350px;
}

.cover-detail .content-detail {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto .5fr .3fr .3fr;
      grid-template-rows: auto .5fr .3fr .3fr;
  row-gap: 20px;
}

.cover-detail .content-detail .judul {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 20px;
  font-size: 1.5em;
}

.cover-detail .content-detail .harga {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 65px;
          column-gap: 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.cover-detail .content-detail .harga p:first-child {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.cover-detail .content-detail .harga .cover-harga .diskon {
  display: inline-block;
  color: #FF2D5F;
  background-color: #ffeaef;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: .8em;
  font-weight: 500;
}

.cover-detail .content-detail .harga .cover-harga .harga-diskon {
  display: inline-block;
  font-size: .8em;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: line-through;
}

.cover-detail .content-detail .harga .cover-harga .harga-produk {
  font-weight: 500;
  font-size: 1.6em;
  color: #fa591d;
}

.cover-detail .content-detail .produsen {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.cover-detail .content-detail .produsen p:first-child {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.cover-detail .content-detail .produsen p:last-child {
  color: rgba(0, 0, 0, 0.7);
}

.cover-detail .content-detail .berat {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 70px;
          column-gap: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.cover-detail .content-detail .berat p:first-child {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.cover-detail .content-detail .berat p:last-child {
  color: rgba(0, 0, 0, 0.7);
}

.cover-detail .content-detail .btn-cover {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -webkit-column-gap: 16px;
          column-gap: 16px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.cover-detail .content-detail .btn-cover a {
  display: inline-block;
  height: 40px;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .cover-detail .content-detail .btn-cover a {
    display: none;
  }
}

.cover-detail .content-detail .btn-cover a.btn-kembali {
  cursor: pointer;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.4);
}

.cover-detail .content-detail .btn-cover a.btn-keranjang {
  cursor: pointer;
  width: 170px;
  color: #fff;
  background-color: #ee4d2d;
}

.cover-deskripsi {
  margin-top: 100px;
  margin-bottom: 100px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr;
      grid-template-rows: auto 1fr;
  row-gap: 20px;
}

.cover-deskripsi .text {
  white-space: pre-line;
  color: black;
}

@media only screen and (max-width: 600px) {
  .cover-deskripsi {
    margin-top: 70px;
  }
}

.cover-deskripsi h2 {
  font-size: 1.3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 20px;
}

.cover-deskripsi p {
  color: rgba(0, 0, 0, 0.4);
  font-size: .95em;
}

.btn-cover-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .btn-cover-mobile {
    z-index: 999;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
    height: 60px;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: .5fr 1fr;
        grid-template-columns: .5fr 1fr;
    padding: 0 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .btn-cover-mobile a {
    display: inline-block;
    height: 40px;
    text-align: center;
    text-decoration: none;
    line-height: 40px;
    border-radius: 6px;
  }
  .btn-cover-mobile a.btn-kembali {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.07);
    color: rgba(0, 0, 0, 0.4);
  }
  .btn-cover-mobile a.btn-keranjang {
    width: 100%;
    color: #fff;
    background-color: #ee4d2d;
  }
}

.cover-keranjang-all {
  position: relative;
  margin: 70px 0;
}

@media only screen and (max-width: 600px) {
  .cover-keranjang-all {
    margin: 30px 0 230px;
  }
}

.cover-keranjang-all h2 {
  font-size: 1em;
}

.cover-keranjang-all .cover-isi {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr .5fr;
      grid-template-columns: 1fr .5fr;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

@media only screen and (max-width: 600px) {
  .cover-keranjang-all .cover-isi {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.cover-keranjang-all .cover-isi h2 {
  margin-bottom: 28px;
}

.cover-keranjang-all .cover-isi .cover-card-produk {
  position: relative;
  display: -ms-grid;
  display: grid;
  row-gap: 20px;
  -webkit-box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
          box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  height: fit-content;
}

@media only screen and (max-width: 600px) {
  .cover-keranjang-all .cover-isi .cover-card-produk {
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 0;
    row-gap: 10px;
    background-color: #F9F9F9;
  }
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: .1fr 1fr .2fr;
      grid-template-columns: .1fr 1fr .2fr;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk img {
  width: 80px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .cover-keranjang-all .cover-isi .cover-card-produk .card-produk {
    -ms-grid-columns: .1fr 1fr;
        grid-template-columns: .1fr 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    row-gap: 10px;
  }
  .cover-keranjang-all .cover-isi .cover-card-produk .card-produk img {
    width: 100%;
    border-radius: 4px;
  }
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk .harga {
  font-size: 1.2em;
  font-weight: 500;
  color: #fa591d;
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk .btn-card-prod {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
  row-gap: 20px;
  align-self: flex-end;
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk .btn-card-prod input {
  border: none;
  outline: none;
  margin: 0 16px !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  width: 40px !important;
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk .btn-card-prod .nice-number button {
  height: 24px;
  width: 24px;
  line-height: 12px !important;
  text-align: center;
  padding: 2px;
  border: none;
  border-radius: 50%;
  outline: none;
}

.cover-keranjang-all .cover-isi .cover-card-produk .card-produk .btn-card-prod a {
  display: inline-block;
  width: 100%;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
}

.cover-keranjang-all .cover-isi .totalbayar {
  position: -webkit-sticky;
  position: sticky;
  border-radius: 8px;
  padding: 20px;
  -webkit-box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
  box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
  display: -ms-grid;
  display: grid;
  row-gap: 10px;
  -ms-grid-rows: repeat(4, auto);
  grid-template-rows: repeat(4, auto);
  background-color: #fff;
  height: fit-content;
}

.cover-keranjang-all .cover-isi .totalbayar .form-voucher {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 4px;
}

.cover-keranjang-all .cover-isi .totalbayar .form-voucher .btn-voucher {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  background-color: #54B149;
  text-align: center;
  line-height: 35px;
  font-weight: 500;
  font-size: 1em;
  height: 35px;
  color: #fff;
  border-radius: 6px;
  padding: 0 8px;
}

.cover-keranjang-all .cover-isi .totalbayar label {
  display: block;
  margin-bottom: 8px;
  font-size: .9em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.cover-keranjang-all .cover-isi .totalbayar input {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  font-size: .9em;
}

@media only screen and (max-width: 600px) {
  .cover-keranjang-all .cover-isi .totalbayar {
    display: none;
  }
}

.cover-keranjang-all .cover-isi .totalbayar .isi-total {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.cover-keranjang-all .cover-isi .totalbayar .isi-total .totalharga {
  color: rgba(0, 0, 0, 0.4);
}

.cover-keranjang-all .cover-isi .totalbayar .isi-total .total {
  justify-self: flex-end;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.cover-keranjang-all .cover-isi .totalbayar .isi-total .totalpotongan {
  justify-self: flex-end;
  font-weight: 600;
  color: #ee4d2d;
}

.cover-keranjang-all .cover-isi .totalbayar .btn-bayar {
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.cover-keranjang-all .cover-isi .totalbayar .btn-bayar a {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
}

.cover-keranjang-all .cover-isi .totalbayar .btn-bayar a.btn-bayar {
  background-color: #ee4d2d;
  width: 100%;
  color: #fff;
}

.totalbayar-mobile {
  display: none;
}

.voucher-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .voucher-mobile {
    display: grid;
    grid-template-rows: repeat(2, auto);
  }

  .voucher-mobile .form-voucher {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 4px;
  }

  .voucher-mobile .form-voucher .btn-voucher {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    background-color: #54B149;
    text-align: center;
    line-height: 35px;
    font-weight: 500;
    font-size: 1em;
    height: 35px;
    color: #fff;
    border-radius: 6px;
    padding: 0 8px;
  }

  .totalbayar-mobile .voucher-mobile label {
    display: block;
    margin-bottom: 8px;
    font-size: 1em;
    font-weight: 500;
    color: #000;
  }
  
  .totalbayar-mobile .voucher-mobile input {
    width: 100%;
    height: 35px;
    border-radius: 6px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    font-size: .9em;
  }
}

@media only screen and (max-width: 600px) {
  .totalbayar-mobile {
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    z-index: 98;
    width: 100%;
    position: fixed;
    display: -ms-grid;
    display: grid;
    padding: 10px 16px 0;
    background-color: #fff;
    bottom: 59px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 12px;
  }

  .totalbayar-mobile .detail {
    display: grid;
    -ms-grid-columns: 1fr .6fr;
        grid-template-columns: 1fr .6fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .totalbayar-mobile .isi-total .totalharga, .totalbayar-mobile .isi-total .total {
    margin: 0;
    font-size: .8em;
  }

  .totalbayar-mobile .isi-total .totalpotongan {
    margin: 0;
    font-size: .8em;
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.6);
  }

  .totalbayar-mobile .isi-total .total {
    font-size: 1em;
    font-weight: 500;
    color: #fa591d;
  }

  .totalbayar-mobile .btn-bayar a {
    display: inline-block;
    height: 35px;
    background-color: #ee4d2d;
    line-height: 35px;
    padding: 0 15px;
    width: 100%;
    font-size: .8em;
    text-align: center;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
  }
}

.login-area {
  display: -ms-grid;
  display: grid;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 100px;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  .login-area {
    top: 100px;
    margin-left: 0;
    margin-right: 0;
  }
}

.cover-login {
  margin: auto;
  width: 350px;
  max-height: 500px;
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 600px) {
  .cover-login {
    width: 100%;
  }
}

.cover-login .close {
  height: 25px;
  width: 25px;
  position: relative;
  cursor: pointer;
  justify-self: flex-end;
}

.cover-login .close span {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  position: absolute;
  width: 20px;
  height: 3px;
  display: inline-block;
  background-color: #000;
  border-radius: 20px;
}

.cover-login .close span::before {
  top: 50%;
  left: 50%;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
  position: absolute;
  content: "";
  width: 20px;
  height: 3px;
  display: inline-block;
  background-color: #000;
  border-radius: 20px;
}

.cover-login h2 {
  margin-bottom: 30px;
  font-size: 1.5em;
}

.cover-login .card-login {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr auto auto auto auto;
      grid-template-rows: auto 1fr auto auto auto auto;
  row-gap: 20px;
}

.cover-login .card-login label {
  display: block;
  margin-bottom: 8px;
  font-size: .9em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.cover-login .card-login input {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  font-size: .9em;
}

.cover-login .card-login .btn-masuk {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  background-color: #1ED07A;
  text-align: center;
  line-height: 40px;
  font-weight: 500;
  font-size: 1em;
  height: 40px;
  color: #fff;
  border-radius: 6px;
  margin: 20px 0;
}

.cover-login .card-login .belumdaftar {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  font-size: .9em;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  margin: auto;
}

.cover-login .card-login .belumdaftar p {
  display: inline-block;
  color: rgba(0, 0, 0, 0.7);
}

.cover-login .card-login .belumdaftar a {
  text-decoration: none;
  color: green;
}

.success-register {
  border-radius: 10px;
  padding: 20px;
}

.success-register h2 {
  margin-bottom: 25px;
  font-size: 1.5em;
}

.success-register p {
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 25px;
}

.success-register .order-now {
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.success-register .order-now a {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  margin: auto;
}

.success-register .order-now a.order-now {
  background-color: #ee4d2d;
  width: 50%;
  color: #fff;
}

/* policy */

.policy {
  display:grid;
  grid-template-rows: auto auto auto auto auto 1fr;
  row-gap: 15px;
  max-width: 1170px;
  width: 100%;
  margin: 40px auto 30px;
}

.policy > h1 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  color:rgba(0,0,0,.8);
}

.policy > p {
  line-height: 22px;
  font-size: 1rem;
  color:rgba(0,0,0,.7);
  
}

.policy > ol {
  padding-left: 40px;
  margin-top: 20px;
}

.policy > ol > li {
  margin-bottom: 10px;
  line-height: 22px;
  color:rgba(0,0,0,.6);
}

.policy > ol > li > span {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

@media only screen and (max-width:670px){
  .policy {
    padding: 0 15px;
  }

  .policy > ol > li {
    font-size: .9rem;
  }

}
